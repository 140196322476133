<template>
    <div class="wrapper">
        <admin-title :title="$route.meta.name"></admin-title>

        <el-form ref="form" :model="formData" :rules="rules" label-width="100px">
            <el-form-item prop="name" label="姓名">
                <el-input size="small" v-model="formData.name" placeholder="姓名"></el-input>
            </el-form-item>

            <el-form-item prop="name" label="手机号码">
                <el-input size="small" v-model="formData.mobile" placeholder="手机号码"></el-input>
            </el-form-item>

            <el-form-item prop="name" label="年龄">
                <el-input size="small" v-model="formData.age" placeholder="年龄"></el-input>
            </el-form-item>

            <el-form-item prop="sex" label="性别" label-width="100px">
                <dict-select :dictKey="DICT_SYSTEM_SEX" :dictValue.sync="formData.sex"></dict-select>
            </el-form-item>

            <el-form-item prop="sex" label="级别" label-width="100px">
                <dict-select :dictKey="DICT_SYSTEM_EMPLOYEE_LEVEL" :dictValue.sync="formData.level"></dict-select>
            </el-form-item>

            <el-form-item label="状态" label-width="100px">
                <dict-select :dict-key="DICT_SYSTEM_EMPLOYEE_STATUS" :dictValue.sync="formData.status"
                             @updateDictValue="updateStatus"></dict-select>
            </el-form-item>
            <el-form-item label=" " label-width="100px">
                <el-button size="small" type="" @click="cancel">取消</el-button>
                <el-button size="small" type="primary" @click="save">保存</el-button>
            </el-form-item>
        </el-form>
        <div style="height:40px;"></div>
    </div>
</template>

<script>
import {addAPI, editAPI, detailAPI} from './api'
import {getAllList} from '../../teacher//teacher-manage/api'

import {DICT_SYSTEM_EMPLOYEE_LEVEL, DICT_SYSTEM_EMPLOYEE_STATUS, DICT_SYSTEM_SEX, DICT_SYSTEM_STATUS} from '@/enum/dict'
import DictSelect from "@/components/dict-select/index.vue";

export default {
    name: 'AddOrEdit',
    components: {
        DictSelect,
    },
    data() {
        return {
            options: [],
            DICT_SYSTEM_SEX,
            DICT_SYSTEM_STATUS,
            DICT_SYSTEM_EMPLOYEE_LEVEL,
            DICT_SYSTEM_EMPLOYEE_STATUS,

            formData: {
                name: '',
                mobile: '',
                sex: 1,
                age: 0,
                status: 20,
                leve: 1,
            },
            rules: {
                name: [{required: true, message: '名称必填', trigger: 'blur'}],
            }
        }
    },

    mounted() {
        this.formData.type = this.$route.params.courseType
        this.getTeacherList()
        if (this.$route.params.id) this.getDetail()
    },

    methods: {
        updateSex(val) {
            this.formData.sex = val
        },
        getVideoUrl(res) {
            this.formData.video_list = res
            console.log(this.formData.video_list)
        },
        getUploadUrl({fileUrl}) {
            this.formData.cover_image = fileUrl
        },
        updateStatus(val) {
            this.formData.status = val
        },
        save() {
            this.$refs.form.validate(async valid => {
                if (valid) {
                    console.log(this.formData)
                    if (this.formData.id) {
                        // 编辑
                        await editAPI(this.formData).then(() => {
                            this.$message.success('编辑成功')
                            this.$router.back()
                        })
                    } else {
                        // 新增
                        await addAPI(this.formData).then(() => {
                            this.$message.success('添加成功')
                            this.$router.back()
                        })
                    }
                } else {
                    return false
                }
            })
        },
        async getDetail() {
            this.formData = await detailAPI(this.$route.params.id)
        },
        async getTeacherList() {
            this.teacherList = await getAllList()
        },
        cancel() {
            this.$router.back(-1)
        }
    }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;

  .el-form /deep/ {
    .el-form-item {
      .el-form-item__content {
        width: 500px;

        .el-cascader,
        .el-select {
          width: 100%;
        }
      }
    }

    .course-editor {
      .el-form-item__content {
        width: 700px;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.el-form /deep/ {
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }

  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }

  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
}
</style>
