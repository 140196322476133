import {
    http
} from '@/http/axios.js'


// 列表
export function getListAPI(params) {
    return http({
        url: `/company/jiaowu/employee/list`,
        method: 'get',
        params
    })
}


// 状态编辑
export function changeStatusAPI(params) {
    return http({
        url: `/company/jiaowu/employee/changeStatus`,
        method: 'post',
        params: params
    })
}

export function removeAPI(params) {
    return http({
        url: `/company/jiaowu/employee/delete`,
        method: 'post',
        params: params
    })
}
export function addAPI(params) {
    return http({
        url: `/company/jiaowu/employee/add`,
        method: 'post',
        params: params
    })
}

export function editAPI(params) {
    return http({
        url: `/company/jiaowu/employee/edit`,
        method: 'post',
        params: params
    })
}

export function detailAPI(id) {
    return http({
        url: `/company/jiaowu/employee/detail`,
        method: 'get',
        params: {id : id}
    })
}
